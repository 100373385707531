import React from "react"
import Slides from "./Slides"

import styled from "styled-components"

const HighLights = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  margin-top: 1.5rem;
  background-color: rgb(0, 0, 0);
  color: #fff;
  border-top: 0.4rem solid #edc16e;

  .events {
    padding: 0.5rem;
    h2 {
      font-size: 1.7rem;
      margin: 1rem;
      text-align: center;
    }

    p {
      line-height: 1.5;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
    padding-right: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;

    h2 {
      margin: 0.5rem !important;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

const EventHightLight = props => {
  return (
    <>
      <HighLights>
        <div className="events">
          <h2>Event Highlights</h2>
          <p>
            The Triton Series has been to various locations around the world,
            holding tournaments and making a lasting impact in local
            communities.
          </p>
        </div>
        <Slides imageSlide={props.images} />
      </HighLights>
    </>
  )
}

export default EventHightLight
