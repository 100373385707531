import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import styled from "styled-components"

const SlidesWapper = styled.div`
  width: 60%;

  .slides {
    position: relative;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    z-index: 1005;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    left: 95%;
  }

  .slick-dots {
    bottom: 10%;
  }
  .slick-dots li {
    background-color: rgba(184, 183, 185, 0.7);
    border-radius: 100%;
  }

  @media (max-width: 960px) {
    width: 100%;
    padding: 0.3rem;
  }
`

class Slides extends React.Component {
  render() {
    const photos = this.props.imageSlide
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 3000,
    }

    return (
      <>
        <SlidesWapper>
          <Slider {...settings}>
            {photos.map(photo => {
              return (
                <div key={photo.wordpress_id}>
                  <img src={photo.source_url} widht="100%" alt={photo.title} />
                </div>
              )
            })}
          </Slider>
        </SlidesWapper>
      </>
    )
  }
}

export default Slides
